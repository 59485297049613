import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: {auth: false, title: 'Home'},
        component: () => import('../components/Home/Home'),
    },
    {
        path: '/about',
        name: 'About',
        meta: {auth: false, title: 'About'},
        component: () => import('../components/Home/About'),
    },
    {
        path: '/features',
        name: 'Features',
        meta: {auth: false, title: 'Features'},
        component: () => import('../components/Home/Features'),
    },
    {
        path: '/privacy-policy',
        name: 'Privacy',
        meta: {auth: false, title: 'Privacy Policy'},
        component: () => import('../components/Home/Privacy'),
    },
    {
        path: '/login',
        name: 'Login',
        meta: {auth: false, title: 'Login'},
        component: () => import('../components/Views/Auth/Login'),
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        meta: {auth: false, title: 'Forgot Password'},
        component: () => import('../components/Views/Auth/ForgotPassword'),
    },
    {
        path: '/reset-password',
        name: 'PasswordReset',
        meta: {auth: false, title: 'Reset Password'},
        component: () => import('../components/Views/Auth/PasswordReset'),
    },
    {
        path: '/google/auth/:id',
        props: true,
        meta: {auth: false, title: 'Google Verification'},
        component: () => import('../components/Views/Auth/Google'),
    },
    {
        path: '/register',
        name: 'Register',
        meta: {auth: false, title: 'Register'},
        component: () => import('../components/Views/Auth/Register'),
    },
    {
        path: '/verification',
        name: 'Verification',
        meta: {auth: false, title: 'Verification'},
        component: () => import('../components/Views/Auth/Verification'),
    },

    // Public
    {
        path: '/guest/survey/:code/:slug',
        props: true,
        meta: {auth: false, title: 'Fill Survey'},
        component: () => import('../components/Public/Fill'),
    },

    // Dashboard
    {
        path: '/dashboard',
        meta: {auth: true, title: 'Dashboard'},
        component: () => import('../components/Views/Dashboard/Dashboard'),
    },
    {
        path: '/surveys/:tab?',
        props: true,
        meta: {auth: true, title: 'Explore Surveys'},
        component: () => import('../components/Views/Posts/Surveys'),
    },
    {
        path: '/surveys/flag/:code',
        props: true,
        meta: {auth: true, title: 'Flag Survey'},
        component: () => import('../components/Views/Posts/Flag'),
    },
    {
        path: '/surveys/ratesurvey/:code',
        props: true,
        meta: {auth: true, title: 'Rate Survey'},
        component: () => import('../components/Views/Posts/RateSurvey'),
    },
    {
        path: '/surveys/:code/:slug?',
        props: true,
        meta: {auth: true, title: 'Fill Survey'},
        component: () => import('../components/Views/Posts/ViewSurvey'),
    },
    {
        path: '/users/:id?',
        props: true,
        meta: {auth: true, title: 'User Management'},
        component: () => import('../components/Views/Users/Users'),
    },
    {
        path: '/users/permissions/:id',
        props: true,
        meta: {auth: true, title: 'User Management'},
        component: () => import('../components/Views/Users/Permissions'),
    },
    // Profile
    {
        path: '/profile/surveys/create/:id?',
        props: true,
        meta: {auth: true, title: 'Create Survey'},
        component: () => import('../components/Views/Posts/CreateSurvey'),
        children: [
            {path: 'settings', component: () => import('../components/Views/Posts/Settings')},
        ]
    },
    {
        path: '/profile/surveys/:tab?',
        props: true,
        meta: {auth: true, title: 'My Surveys'},
        component: () => import('../components/Views/Profile/Surveys'),
    },
    {
        path: '/profile/surveys/view/:code/',
        props: true,
        component: () => import('../components/Views/Profile/Survey'),
        children: [
            {
                path: 'details',
                meta: {auth: true, title: 'Survey Details'},
                component: () => import('../components/Views/Profile/Survey/Details')
            },
            {
                path: 'questionnaire',
                meta: {auth: true, title: 'Define Questionnaire'},
                component: () => import('../components/Views/Profile/Survey/Questions')
            },
            {
                path: 'settings',
                meta: {auth: true, title: 'Survey Settings'},
                component: () => import('../components/Views/Profile/Survey/SurveySettings')
            },
            {
                path: 'sections',
                meta: {auth: true, title: 'Define Sections'},
                component: () => import('../components/Views/Profile/Survey/SurveySections')
            },
            {
                path: 'statistics',
                meta: {auth: true, title: 'Survey Statistics'},
                component: () => import('../components/Views/Reports/Survey.vue')
            },
            {
                path: 'reports',
                meta: {auth: true, title: 'Survey Reports'},
                component: () => import('../components/Views/Profile/Survey/SurveyReports')
            },
            {
                path: 'share',
                meta: {auth: true, title: 'Share Survey'},
                component: () => import('../components/Views/Profile/Survey/SurveyShare')
            },
            {
                path: 'respondents',
                meta: {auth: true, title: 'Respondents'},
                component: () => import('../components/Views/Profile/Survey/SurveyRespondents')
            },
            {
                path: 'statistics',
                meta: {auth: true, title: 'Survey Statistics'},
                component: () => import('../components/Views/Profile/Survey/SurveyStatistics')
            },
            {
                path: 'preview',
                meta: {auth: true, title: 'Preview Survey'},
                component: () => import('../components/Views/Profile/Survey/SurveyPreview')
            },
            {
                path: 'logs',
                meta: {auth: true, title: 'Survey Logs'},
                component: () => import('../components/Views/Profile/Survey/SurveyLogs')
            },
            {
                path: 'map',
                meta: {auth: true, title: 'Respondents Map'},
                component: () => import('../components/Views/Profile/Survey/SurveyMap')
            },

        ]
    },
    {
        path: '/responses',
        name: 'ResponsesSummary',
        component: () => import('../components/Views/Profile/ResponsesSummary'),
    },

    {
        path: '/profile',
        name: 'Profile',
        meta: {auth: true, title: 'My Profile'},
        component: () => import('../components/Views/Profile/Profile'),
    },
    {
        path: '/posts/:id',
        name: 'PostList',
        props: true,
        component: () => import('../components/Views/Profile/MyProfile/PostList'),
    },

    // View other user profile
    // Profile
    {
        path: '/user/:id',
        props: true,
        meta: {auth: true, title: 'User Profile'},
        component: () => import('../components/Views/User/UserProfile')
    },

    //searchpage
    {
        path: '/searchresults',
        name: 'SearchPage',
        props: true,
        meta: {auth: true, title: 'Search'},
        component: () => import('../components/Views/Profile/Search/SearchPage'),
    },
    //notifications
    {
        path: '/notifications',
        name: 'Notification',
        props: true,
        meta: {auth: true, title: 'Notifications'},
        component: () => import('../components/Views/Profile/Notifications'),
    },

    //reports
    {
        path: '/report/:code',
        props: true,
        meta: {auth: true, title: 'Reports'},
        component: () => import('../components/Views/Reports/Survey'),
    },

    {
        path: '/404',
        name: 'PageNotFound',
        meta: {
            title: 'Page Not Found'
        },
        component: () => import('../components/Views/Error404.vue')
    },
    {
        path: '*',
        redirect: '/404'
        /*
          beforeEnter (to, from, next) {
            { next('/404') }
          }
          */
    }
];

const router = new VueRouter({
    mode: 'history', // require service support
    scrollBehavior: () => ({y: 0}),
    linkActiveClass: 'active',
    linkExactActiveClass: "active",
    routes // short for `routes: routes`
});

export default router;

