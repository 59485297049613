<template>
    <div>
        <Alerts :_success="success" :_error="error"></Alerts>

        <form v-if="survey" @submit.prevent="submitForm()">

            <div v-if="hasRespondent" class="survey-intro">
                <h4>Thank you!</h4>
                <p v-if="survey.end_message">{{ survey.end_message }}</p>
                <p class="mb-0 align-center">
                    You responded to this survey on:
                    <strong>{{ moment(respondent.created_at).format("MMM D, YYYY HH:mm") }}</strong>
                </p>
            </div>

            <div v-else>
                <div v-if="survey.show_description == 1 && !start_survey" class="survey-intro">
                    <h3>Welcome</h3>
                    <p class="text-bold">{{ survey.start_message }}</p>
                    <button class="custom-btn custom-btn-primary" @click="startSurvey()">Start survey</button>
                </div>

                <div v-else>
                    <div v-if="survey.section_id == null" class="questionnaire-list">
                        <div class="row">
                            <div class="col-lg-3">
                                <select class="form-select" v-model="lang" @change="updateSurveyLang()">
                                    <option v-for="l in getLanguages()" :value="l.code" :key="l.code">{{
                                            l.name
                                        }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <hr>

                        <div v-for="(input, index) in getQuestions" :key="index" class="mb-2 questionnaire-list-item">

                            <span class="question-content">
                                <h6 v-if="input.canShow && input.type !== 'checkbox' && input.type !== 'instruction'"
                                    class="question mb-3" :title="input.id">
                                    <!--<strong class="light-blue">{{ ++index }}.</strong>-->
                                    {{ input.title }}<sup class="text-danger">&nbsp;{{
                                        input.isRequired ? '*' : ''
                                    }}</sup>

                                    <select v-if="respond == false" v-model="move_to" @change="moveTo(input)"
                                            style="font-size: smaller; width: 100px; float: right">
                                        <option value="">--move to--</option>
                                        <option v-for="i in getQuestions" :key="i.id" :value="i.id">{{
                                                i.title
                                            }}</option>
                                    </select>
                                </h6>
                                <!-- Instruction -->
                                <div v-if="input.type == 'instruction'">
                                    <div class="qn-instruction">
                                        <p class="lead">{{ input.title }}</p>
                                    </div>
                                </div>

                                <!-- Select-->
                                <div v-if="input.type == 'select' && input.canShow">
                                    <b-form-select class="form-select" v-model="response[input.id]" :disabled="wait"
                                                   :required="input.isRequired" size="lg">
                                        <template #first>
                                            <b-form-select-option :value="null" disabled>-- Please select an option --
                                            </b-form-select-option>
                                        </template>
                                        <b-form-select-option v-for="i in input.getOptions" :key="i" :value="i">
                                            {{ i }}
                                        </b-form-select-option>
                                    </b-form-select>
                                </div>

                                <!-- Input Text-->
                                <div v-if="input.type == 'text' && input.canShow">
                                    <b-form-input @blur="checkEncryption(input)" type="text"
                                                  v-model="response[input.id]"
                                                  :disabled="wait" size="lg" :required="input.isRequired"
                                                  :placeholder="input.placeholder">
                                    </b-form-input>
                                </div>

                                <!-- Input Number-->
                                <div v-if="input.type == 'number' && input.canShow">
                                    <b-form-input type="number" v-model="response[input.id]" :disabled="wait" size="lg"
                                                  :required="input.isRequired" :placeholder="input.placeholder" class="mb-3">
                                    </b-form-input>

                                   <input v-if="input.multiple_answers" v-model="response[input.id + '~']"
                                          class="form-control mb-3" placeholder="Additional information">
                                </div>

                                <!-- E-mail -->
                                <div v-if="input.type == 'email' && input.canShow">
                                    <input class="form-control" type="email" @blur="checkEncryption(input)"
                                           :required="input.isRequired" v-model="response[input.id]"
                                           :placeholder="input.placeholder" :disabled="wait">
                                </div>

                                <!-- GPS -->
                                <div v-if="input.type == 'gps' && input.canShow">
                                    <input class="form-control" type="text" :required="input.isRequired"
                                           v-model="response[input.id]" :disabled="wait">
                                </div>

                                <!-- Checkbox -->
                                <div v-if="input.type == 'checkbox' && input.canShow">
                                    <div class="form-check">
                                        <input class="form-check-input" :id="input.id" type="checkbox"
                                               v-model="response[input.id]" :value="true" :disabled="wait"
                                               :required="input.isRequired">
                                        <label class="form-check-label" :for="`${input.id}`">{{ input.title }}</label>
                                    </div>
                                </div>

                                <!-- Radio -->
                                <div v-if="input.type == 'radio' && input.canShow">
                                    <input type="radio" v-model="response[input.id]" :value="true" :disabled="wait"
                                           :required="input.isRequired">
                                    &nbsp;{{ input.title }}
                                </div>

                                <!--                        &lt;!&ndash; File &ndash;&gt;-->
                                <!--                        <div v-if="input.type == 'file' && input.canShow">-->
                                <!--                            <label :for="input.id" class="form-label">Upload file</label>-->
                                <!--                            <b-form-file-->
                                <!--                                plain-->
                                <!--                                class="form-control form-control-lg"-->
                                <!--                                :v-model="input.id"-->
                                <!--                                accept=".jpg, .png, .gif"-->
                                <!--                                :disabled="wait"-->
                                <!--                                id="input.id"-->
                                <!--                                :required="input.isRequired"-->
                                <!--                                @change="handleFileUpload($event)"-->
                                <!--                            >-->
                                <!--                            </b-form-file>-->
                                <!--                        </div>-->

                                <!-- Date -->
                                <div v-if="input.type == 'date' && input.canShow">
                                    <input type="date" class="form-control" v-model="response[input.id]"
                                           :disabled="wait"
                                           :required="input.isRequired">
                                </div>

                                <!-- Time -->
                                <div v-if="input.type == 'time' && input.canShow">
                                    <input type="time" class="form-control" v-model="response[input.id]"
                                           :disabled="wait"
                                           :required="input.isRequired">
                                </div>

                                <!-- Duration -->
                                <div v-if="input.type == 'duration' && input.canShow">
                                    <input type="datetime" class="form-control html-duration-picker" min="0" max="100"
                                           v-model="response[input.id]" :disabled="wait" :required="input.isRequired">
                                </div>

                                <!-- Multiple choice-->
                                <div v-if="input.type === 'multiple' && input.canShow">
                                    <div class="ml-4" v-for="(i, a) in input.getOptions" :key="a">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" :value="i" :name="input.id"
                                                   v-model="response[input.id]" :disabled="wait"
                                                   @change="validateMultiselect(input)">
                                            <label class="form-check-label" :for="input.id">{{ i }}</label>
                                        </div>
                                        <textarea v-if="input.multiple_answers" v-model="response[input.id + '~' + a]"
                                                  rows="2" class="form-control mb-3"
                                                  placeholder="Additional information (write each on a new line)">
                                        </textarea>
                                    </div>
                                </div>


                                <!-- Single choice-->
                                <div v-if="input.type == 'single' && input.canShow">
                                    <div class="ml-4" v-for="(i, a) in input.getOptions" :key="a">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" :value="i" :name="input.id"
                                                   v-model="response[input.id]" :disabled="wait"
                                                   :required="input.isRequired">
                                            <label class="form-check-label" :for="input.id">{{ i }}</label>
                                        </div>
                                    </div>
                                </div>

                                <!-- input for (single choice, multiple choice and dropdown) -->
                                <div v-if="input.allow_other && input.canShow">
                                    <input type="text" v-model="response[input.id + '~other']" class="mt-3 form-control"
                                           placeholder="Other...">
                                </div>

                                <!-- Textarea -->
                                <div v-if="input.type == 'narration' && input.canShow">
                                    <textarea class="form-control" v-model="response[input.id]" rows="6"
                                              :placeholder="input.placeholder" :disabled="wait"
                                              :required="input.isRequired"></textarea>
                                </div>

                                <!-- Help Text-->
                                <p v-if="input.canShow" class="mt-1 mb-4 text-muted small"><i>{{ input.help }}</i></p>
                            </span>
                        </div>
                    </div>

                    <div v-if="survey.code !== 4526725 && user" class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" v-model="anonymously" :disabled="wait">
                        <label class="form-check-label">Post anonymously (You are logged in as
                            <strong>{{ user.name }}</strong>)
                        </label>
                    </div>

                    <div class="form-group">
                        <vue-recaptcha ref="recaptcha" size="invisible" @verify="onVerified" @expired="onExpired"
                                       :sitekey="sitekey">
                        </vue-recaptcha>
                    </div>

                    <button type="submit" v-if="respond" class="custom-btn custom-btn-success">
                        <template v-if="wait" disabled>
                            <i class="fas fa-spinner fa-spin"></i>
                            Please wait..
                        </template>
                        <template v-else>
                            <i class="fas fa-check"></i>
                            Submit Response
                        </template>
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Alerts from "./Alerts";
import VueRecaptcha from 'vue-recaptcha'

export default {
    name: "Questionnaire",
    props: ['survey', 'respond', 'code', '_error', 'hours', 'minutes'],
    components: {Alerts, VueRecaptcha},
    data() {
        return {
            geo: '',
            anonymously: false,
            response: {},
            respondent: {},
            hasRespondent: false,
            start_survey: false,
            languages: [],

            questions: [],
            files: '',
            validated: true,
            lang: 'en',
            interval: null,
            isRunning: false,
            startTime: null,
            times: [0, 0, 0, 0],
            frameId: null,
            sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
            move_to: '',
        }
    },
    mounted() {
        this.initResponse()
        this.loadLanguages()

        if (this.respond) {
            this.loadMyRespondent()
        }

        if (this.survey.collect_geo) {
            this.getUserLocation()
        }
    },
    watch: {
        geo() {
        }
    },
    computed: {
        user() {
            return JSON.parse(localStorage.getItem(process.env.VUE_APP_NAME + '_user'))
        },
        getQuestions() {
            return this.survey.questions.map(qn => {
                qn['canShow'] = this.canShow(qn)
                return qn
            })
        },
        //     isCheckboxSelected() {
        //   return (option) => {
        //     return !this.response[input.id] || !this.response[input.id].includes(option);
        //   };
        // }
    },

    methods: {
        moveTo(input) {
            this.$http.get(`/api/profile/survey/${this.survey.code}/sequence?id=` + input.id + '&to=' + this.move_to).then(res => {
                this.survey.questions = res.data.data
            }).catch(error => {
                this.showError(error)
            })

        },
        getLanguages() {
            let languages = this.survey.languages.map(a => a.lang)
            return this.languages.filter(a => languages.includes(a.code))
        },
        checkEncryption(input) {
            if (input.encrypt == 1) {
                this.response[input.id] = this.response[input.id].split('').sort(function () {
                    return 0.5 - Math.random()
                }).join('');
            }
        },
        initResponse() {
            if (this.survey) {
                this.survey.questions.forEach((a) => {
                    if (a.type == 'multiple') {
                        this.response[a.id] = []
                        if (a.multiple_answers) {
                            a.getOptions.forEach((option, index) => {
                                option += ''
                                this.response[a.id + '~' + index] = []
                            })
                        }
                    }
                })
            }
        },
        loadLanguages() {
            this.$http.get('/api/languages').then(response => {
                this.languages = response.data.data
            })
        },
        updateSurveyLang() {
            let lang = this.survey.languages.find(a => a.lang === this.lang)
            if (lang !== undefined) {
                this.survey.title = lang.title
                this.survey.tags = lang.tags
                this.survey.description = lang.description
                this.langSet = true

                // Questions
                this.survey.questions.map(qn => {
                    let _lang = qn.languages.find(b => b.lang === this.lang)

                    if (_lang !== undefined) {
                        qn.title = _lang.title
                        qn.options = _lang.options
                        qn.value_is = _lang.value_is
                        qn.getOptions = _lang.getOptions
                        qn.placeholder = _lang.placeholder
                    } else {
                        qn.title = ''
                        qn.options = ''
                        qn.value_is = ''
                        qn.getOptions = ''
                        qn.placeholder = ''
                    }
                })
            }
        },
        startSurvey() {
            this.start_survey = true
            if (this.isRunning) throw new Error('Stopwatch has already started.');
            this.isRunning = true;
            if (!this.startTime) this.startTime = performance.now();
            this.$emit('start', this.startTime);
            this.frameId = requestAnimationFrame(this.step);
        },
        // toggleTimer() {
        //     if (this.isRunning) {
        //         clearInterval(this.interval);
        //         console.log('timer stops');
        //     } else {
        //         this.interval = setInterval(this.incrementTime, 1000);
        //     }
        //     this.isRunning = !this.isRunning
        // },
        // incrementTime() {
        //     this.time = parseInt(this.time) + 1;
        // },
        canShow(input) {
            if (input.show_when === null) {
                return true

            } else {
                let value = this.response[input.show_when]

                if (input.operator === '!=') {
                    return (value && value !== input.value_is)

                } else if (input.operator === '>=') {
                    return (!value || parseFloat(value) >= parseFloat(input.value_is))

                } else if (input.operator === '<=') {
                    return (!value || parseFloat(value) <= parseFloat(input.value_is))

                } else if (input.operator === '><') {
                    let base = input.value_is.split('-')
                    return (!value === false && (parseFloat(value) >= (parseFloat(base[0])) && parseFloat(value) <= parseFloat(base[1])))

                } else if (input.operator === 'n') {
                    return (!value === false)

                } else if (input.operator === '!n') {
                    return (!value === true)

                } else {
                    return (value === input.value_is)
                }
            }
        },
        userId() {
            let auth = this.user
            return auth ? auth.id : ''
        },
        handleFileUpload() {
            this.files = event.target.files[0];
        },
        validateMultiselect(input) {
            if (this.response[input.id].length == 0) {
                this.validated = false
            } else {
                this.validated = true
            }
        },
        submitForm() {
            this.$refs.recaptcha.execute();
            if (!this.isRunning) throw new Error('Stopwatch has not been started yet.');
            this.isRunning = false;
            this.startTime = null;
            this.times = [0, 0, 0, 0];
            this.$emit('stop', performance.now(), this.time);
            cancelAnimationFrame(this.frameId);
        },
        onExpired: function () {
            this.$refs.recaptcha.reset();
        },
        onVerified: function (token) {
            this.response.recaptcha_token = token
            this.saveResponse()
        },
        saveResponse() {
            window.scroll(0, 0)
            this.clear()

            if (!this.validated) {
                this.error = 'You have not selected anything on multiselect question(s)'

            } else {
                let url = '/api/survey/respond/' + this.survey.code + '?user_id=' + this.userId()
                this.wait = true
                this.success = 'Please wait...'
                this.$http.post(url, {
                    data: this.response,
                    geo: this.geo,
                    lang: this.lang,
                    owner_id: null,
                    start_time: null,
                    end_time: null,
                    anonymously: this.anonymously,
                }).then(response => {
                    let msg = "Thank you for participating in this survey."
                    this.success = msg
                    this.hasRespondent = true
                    this.respondent = response.data.data
                    console.log(this.survey.end_message)

                }).catch(error => {
                    this.showError(error)
                }).then(() => {
                    this.wait = false
                })
            }
        },
        loadMyRespondent() {
            this.$http.get(`/api/survey/respond/${this.survey.code}?user_id=${this.userId()}`).then(res => {
                let respondent = res.data.data
                if (respondent && respondent.payload) {
                    this.hasRespondent = true
                    this.response = respondent.payload
                    this.respondent = respondent
                }
            })
        },

        getUserLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.showPosition, this.showPositionError)
            }
        },
        showPosition(position) {
            let lat = position.coords.latitude
            let lon = position.coords.longitude
            this.geo = lat + ',' + lon
        },
        showPositionError(error) {
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    this.error = "User denied the request for Geolocation."
                    break;
                case error.POSITION_UNAVAILABLE:
                    this.error = "Location information is unavailable."
                    break;
                case error.TIMEOUT:
                    this.error = "The request to get user location timed out."
                    break;
                case error.UNKNOWN_ERROR:
                    this.error = "An unknown error occurred."
                    break;
            }
        },
        formatTimes(times = this.times) {
            const hours = pad0(times[0], 2);
            const minutes = pad0(times[1], 2);
            const seconds = pad0(times[2], 2);
            const milliseconds = pad0(Math.trunc(times[3] % 100), 2);
            if (this.hours) {
                return `${hours}:${minutes}:${seconds}:${milliseconds}`;
            }
            if (this.minutes) {
                return `${minutes}:${seconds}:${milliseconds}`;
            }
            return `${seconds}:${milliseconds}`;

            function pad0(value, count) {
                let result = value.toString();
                while (result.length < count) {
                    result = '0' + result;
                    --count;
                }
                return result;
            }
        },
        step(timestamp) {
            if (!this.isRunning) return;
            this.calculate(timestamp);
            this.startTime = timestamp;
            this.time = this.formatTimes();
            this.frameId = requestAnimationFrame(this.step);
        },
        calculate(timestamp) {
            const diff = timestamp - this.startTime;
            this.times[3] += diff / 10;
            if (this.times[3] >= 100) {
                this.times[3] -= 100;
                this.times[2] += 1;
            }
            if (this.times[2] >= 60) {
                this.times[2] -= 60;
                this.times[1] += 1;
            }
            if (this.times[1] >= 60) {
                this.times[1] -= 60;
                this.times[0] += 1;
            }
        },
    }
}
</script>

<style scoped></style>
